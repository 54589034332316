import React from 'react';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import classes from './styles.module.scss';
import DedicatedPmsList from '../../components/DedicatedPmsList';

const DedicatedPmsPage = () => {
  return (
    <div className={classes.DedicatedPmsPage}>
      <HeaderMenuContainer headerTitle="Dedicated PM(s)" isUserBadgeVisible>
        <DedicatedPmsList />
      </HeaderMenuContainer>
    </div>
  );
};
export default DedicatedPmsPage;

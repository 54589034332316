import React, { useCallback, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { USER_ROLES } from '../../constants/main';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import UserService from '../../services/UserService';
import EngagementService from '../../services/EngagementService';
import useDebounce from '../../hooks/useDebounce';
import BackButton from '../../components/UI/Buttons/BackButton';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import Input from '../../components/UI/Input';
import AddCustomerDropdown from '../../components/Engagements/AddCustomerDropdown';
import ErrorMessage from '../../components/UI/ErrorMessage';
import QuillTextEditor from '../../components/QuillTextEditor/QuillTextEditor';
import classes from './styles.module.scss';
import useUser from '../../hooks/useUser';

const validationSchema = yup.object({
  title: yup.string().trim().required('Title is required.'),
  description: yup.string().trim().required('Description is required.'),
  customerId: yup.string().required('Customer is required.'),
});

const CreateEngagementPage = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerSearchValue, setCustomerSearchValue] = useState('');
  const debouncedCustomerSearchValue = useDebounce(customerSearchValue, 500);
  const {
    isProjectManager,

    currentUser,
  } = useUser();
  const history = useHistory();

  const { data: customers } = useQuery(
    ['customers', debouncedCustomerSearchValue],
    () =>
      UserService.getUsers({
        roles: [USER_ROLES.CUSTOMER],
        search: debouncedCustomerSearchValue,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { mutate: createEngagementMutation } = useMutation(
    EngagementService.createEngagement,
    {
      onSuccess: () => {
        history.push('/engagements');
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleSubmit = useCallback(({ title, description, customerId }) => {
    createEngagementMutation({
      title,
      description,
      customerId,
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      customerId: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCustomerSelect = useCallback(
    (customer) => {
      formik.setFieldValue('customerId', customer.id);
      setSelectedCustomer(customer);
    },
    [formik]
  );

  const handleCustomerSearchValueChange = useCallback((event) => {
    const { value } = event.target;

    setCustomerSearchValue(value);
  }, []);

  const clearCustomerSearchValue = useCallback(() => {
    setCustomerSearchValue('');
  }, []);
  const getUsers = () => {
    if (isProjectManager) {
      return currentUser.assignedUsers;
    }
    return customers;
  };
  return (
    <div className={classes.CreateEngagementPage}>
      <HeaderMenuContainer isUserBadgeVisible>
        <BackButton label="Back to Engagements" />

        <form
          onSubmit={formik.handleSubmit}
          className={classes.createEngagementForm}
        >
          <h1 className={classes.title}>Create a new engagement</h1>
          <div className={classes.formContainer}>
            <div className={classes.inputContainer}>
              <Input
                value={formik.values.title}
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Project Title"
                hasError={formik.touched.title && formik.errors.title}
              />
              {formik.touched.title && formik.errors.title && (
                <ErrorMessage message={formik.errors.title} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <QuillTextEditor
                placeholder="Project description..."
                contentContainerClassName={classes.textareaContainer}
                content={formik.values.description}
                onChange={(value) => formik.setFieldValue('description', value)}
                onBlur={() => formik.validateField('description')}
                hasError={
                  formik.touched.description && formik.errors.description
                }
              />
              {formik.touched.description && formik.errors.description && (
                <ErrorMessage message={formik.errors.description} />
              )}
            </div>

            <div className={classes.buttons}>
              <div className={classes.dropdownContainer}>
                <AddCustomerDropdown
                  label="Choose customer"
                  items={getUsers()}
                  selectedItem={selectedCustomer}
                  onItemClick={handleCustomerSelect}
                  onSearch={handleCustomerSearchValueChange}
                  clearSearchValue={clearCustomerSearchValue}
                  onTouched={() => formik.setFieldTouched('customerId', true)}
                  hasError={
                    formik.touched.customerId && formik.errors.customerId
                  }
                />
                {formik.touched.customerId && formik.errors.customerId && (
                  <ErrorMessage message={formik.errors.customerId} />
                )}
              </div>

              <PrimaryButton
                classnames={[classes.createButton]}
                iconName="arrow"
                type="submit"
              >
                Create engagement
              </PrimaryButton>
            </div>
          </div>
        </form>
      </HeaderMenuContainer>
    </div>
  );
};

export default CreateEngagementPage;

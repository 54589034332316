import React from 'react';
import classes from './styles.module.scss';
import Avatar from '../UI/Avatar';
import useUser from '../../hooks/useUser';

const DedicatedPmsList = () => {
  const { currentUser } = useUser();

  const assignedUsers = currentUser?.assignedUsers;
  return (
    <div className={classes.DedicatedPmsList}>
      <h3>Assigned To Your Account</h3>
      {assignedUsers?.length > 0 ? (
        assignedUsers.map((item) => (
          <div key={item.id} className={classes.user}>
            <Avatar
              imagePath={item.avatarPath}
              alt={item.name}
              className={classes.userAvatar}
            />

            <div className={classes.userInfo}>
              <div>
                <div className={classes.userName}>{item.name}</div>
                <div className={classes.note}>{item.note}</div>
              </div>
              <div>
                <a className={classes.LinkButton} href={`mailto:${item.email}`}>
                  {item.email}
                </a>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>
          There are currently no project managers assigned to you. Please reach
          out to us to initiate a new engagement.
        </p>
      )}
    </div>
  );
};

export default DedicatedPmsList;
